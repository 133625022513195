@import 'fonts.css';
/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a, .prose a {
    @apply underline underline-offset-2 text-sky-500 hover:text-sky-700;
  }
  
  .cta-btn {
    @apply no-underline text-white bg-coast-500 border border-4 border-white transition-colors inline-block;
  }
  
  .cta-btn:hover, .cta-btn:active {
    @apply bg-coast-700;
  }
  
  body {
    --sl-input-font-family: 'Recursive';
  }

  .prose code {
    padding: 0 0.2em;
    background: #eee;
  }
  .prose code::before, .prose code::after {
    content: "";
  }

  .discreet-input {
    @apply hidden;
  }
}